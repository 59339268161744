import React from "react";
import {
  IoIosSearch,
  IoIosPeople,
  IoIosTimer,
  IoIosCart,
  IoIosCopy,
  IoMdAnalytics,
  IoIosSettings,
  IoIosCheckbox,
  IoIosCheckboxOutline,
  IoIosFlash,
  IoIosFiling,
  IoIosLock,
  IoIosCheckmark,
  IoIosMailUnread,
  IoIosHeart,
} from "react-icons/io";
import { Link } from "gatsby";
import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Feature,
  CallToAction,
  Modal,
  CustomerQuote,
  ImageGraphic,
  LogoScroller,
} from "../components/site";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const FranchisePayroll = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout>
      <Seo
        title="Payroll Software for Franchises | PayHero"
        description="Focus on scaling your business. Let payroll take care of itself. PayHero is the online payroll and payday filing system for New Zealand franchises. Get started."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50} centerOnMobile>
              <Box stackGap={20}>
                <h1 className="-lg -primary badge">Payroll for Franchises</h1>
                <h2 className="h1 -fontLarge">
                  Focus on scaling your business{" "}
                  <span className="-fontPrimary">
                    and let payroll take care of itself
                  </span>
                </h2>
                <h4>
                  Look after staff, get their entitlements right and understand
                  wage costs across the group. PayHero is the online payroll and
                  payday filing system for New Zealand franchises.
                </h4>
              </Box>
              <Box stackGap={15}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    onClick={toggleModal}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
                <p css={{ color: "#999" }}>
                  Get started in minutes, no credit card required.
                </p>
              </Box>
              <LogoScroller>
                <Image
                  filename="FlexiTimeFranchiseCustomers.png"
                  alt="PayHero Franchise Payroll Customers"
                  centerImage
                />
              </LogoScroller>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="FranchiseHero.jpg"
                alt="Payroll for Franchise Businesses"
                centerImage
                addShadow
                rounded
              />
            </Box>
          </Row>
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoIosCheckbox />
                <br />
                Stress free payroll compliance
              </h3>
              <p>
                Franchises can be a hot bed of potentially tricky payroll
                scenarios with part time and variable hour staff and franchisees
                with little payroll experience. PayHero follows the latest MBIE
                Holidays Act guidelines to ensure employees maintain the correct
                holiday entitlements, even when work patterns change.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosTimer />
                <br />
                Take the time out of timesheets
              </h3>
              <p>
                Give your franchisees the gift of easy timesheet management with
                PayHero. Choose from self-service employee timesheets, GPS
                timesheets from their phone, or our photo clock in and out app
                to send accurate timesheets straight to payroll and
                automatically manage rest and meal breaks.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosCart />
                <br />
                Easily manage multiple locations
              </h3>
              <p>
                Manage time, leave and payroll for different locations with
                PayHero Teams. Assign staff members to one or more sites and
                give local managers access to manage employee leave and
                timesheets at their site. Track your wage costs with Xero
                tracking category integration.
              </p>
            </Box>
          </Row>
          <CustomerQuote
            quote="The only software on the market currently able to accurately calculate the leave entitlements for employees with irregular working patterns, without manual intervention, is PayHero."
            name="Irene Bennetts"
            company="Admin Army"
            pic="Irene_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container className="secondary">
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={40}>
              <h2>
                Protect your brand
                <br />
                <span className="-fontLight">
                  In these days of increased scrutiny when it comes to payroll
                  compliance, ensure your franchisees have the most compliant
                  online payroll system available and access to the support they
                  need to get it right.
                </span>
              </h2>
            </Box>
            <Box size={50} stackGap={60}>
              <Feature
                icon={<IoIosLock key={0} />}
                title="Built for Holidays Act compliance"
                description="PayHero follows the very latest Holidays Act guidance from MBIE with lots of inbuilt automation to help employers get it right. As a cloud based payroll system, any changes to payroll and labour laws are automatically applied. No downloads or time consuming updates for you or your franchisees - just business as usual."
              />
              <Feature
                icon={<IoIosCheckmark key={0} />}
                title="Taking care of business "
                description="There’s a lot of red tape for business owners these days. PayHero automates time consuming and tedious tasks so your franchisees can get on with what’s more important. Online timesheets provide a record of hours worked by employees each day. Our photo time clock takes care of rest and meal break compliance and a health and safety record of who’s onsite in real time."
              />
              <Feature
                icon={<IoIosMailUnread key={0} />}
                title="Help to get it right"
                description="If you have a payroll expert at head office, your franchisees can invite them to temporarily access their PayHero account to help with any troubleshooting. Our friendly Wellington-based support team is available by phone or email whenever you need it. "
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <h2 className="-textCenter">
            Look after your bottom line and let PayHero take care of the tricky
            payroll stuff
          </h2>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50} stackGap={40}>
              <Feature
                icon={<IoIosCheckboxOutline key={0} />}
                title="Easy public holiday payroll"
                description="PayHero automatically determines whether a public holiday is an otherwise working day for an employee based on their recent work pattern and calculates their entitlements accordingly."
              />
              <Feature
                icon={<IoIosTimer key={0} />}
                title="Take the time out out of timesheets"
                description="Give your franchisees the gift of easy timesheet management with PayHero. Choose from self-service employee timesheets, GPS timesheets from their phone, or our photo clock in and out app to send accurate timesheets straight to payroll and automatically manage rest and meal breaks. Plus, allow team managers to approve timesheets to minimise admin for the payroll team."
              />
              <Feature
                icon={<IoIosFlash key={0} />}
                title="Automated payday filing"
                description={[
                  <Link to="/payday-filing">Payday filing</Link>,
                  <span>
                    {" "}
                    is a piece of cake in PayHero – your franchisees just
                    connect their account to IRD and we’ll automatically send
                    their payroll information through after every pay.
                  </span>,
                ]}
              />
              <Feature
                icon={<IoIosPeople key={0} />}
                title="Employee self-service"
                description="Ensure employees have the tools they need to do their best work and that staff across your entire group have the same great experience. Employees can view leave balances and request leave from the PayHero app. They can access their old payslips too, submit expense claims, and complete online timesheets (including GPS location stamping) if you enable that option."
              />
              <Feature
                icon={<IoIosFiling key={0} />}
                title="Straight through to accounts"
                description="Automatically send payroll data through to Xero. Keep it simple or assign wage costs to different account codes and tracking categories for a more detailed break down."
              />
            </Box>
            <Box size={50}>
              <Image
                filename="PayrollForFranchises_PayHero.jpg"
                alt="PayHero | Payroll Software for Franchises"
                centerImage
                rounded
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper noPaddingBottom>
          <h2 className="-textCenter">
            PayHero makes franchise <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>payroll</Link> easy
          </h2>
          <Box stackGap={40}>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoIosSettings />
                  <br />
                  Flexible setup
                </h3>
                <p>
                  Easily set up different departments or locations for employee
                  timesheets and reporting on costs.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoMdAnalytics />
                  <br />
                  Insight into finances and staffing
                </h3>
                <p>
                  Track your wage costs across departments, ensuring you never
                  go over budget. Compare staff costs with your revenue to
                  ensure you have the right number of staff rostered on at busy
                  and quiet periods.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosCopy />
                  <br />
                  Keep track of HR documents
                </h3>
                <p>
                  Store employment contracts, IRD forms, work permits, licences
                  and other important documents on each employee’s profile.
                </p>
              </Box>
            </Row>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoIosLock />
                  <br />
                  Seriously secure
                </h3>
                <p>
                  We use bank-level security and automatic, daily online backups
                  to multiple servers to ensure your sensitive payroll data and
                  employee information is kept safe.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosHeart />
                  <br />
                  Share the love
                </h3>
                <p>
                  Add personal notes to payslips, and receive dashboard
                  reminders for work anniversaries and birthdays so you never
                  miss a chance to celebrate your staff and recognise their
                  contribution.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosSearch />
                  <br />
                  Try before you buy
                </h3>
                <p>
                  With a fully featured{" "}
                  <Link to="/signup">free 14 day trial</Link>, you can take
                  PayHero for a risk-free test run before committing.
                </p>
              </Box>
            </Row>
          </Box>
          <Image
            filename="PayHeroHero_Cropped_Hospo.png"
            alt="PayHero"
            maxWidth={1000}
            centerImage
            addShadow
          />
        </Wrapper>
      </Container>
      <CallToAction
        title="Franchise payroll made super easy"
        description="14 Days Free · First Pay Walkthrough · No Credit Card Required"
        showSalesButton
      />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default FranchisePayroll;
